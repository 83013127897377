import { jwtDecode } from 'jwt-decode';
import Cookies from './cookies';

const verifyMongodbId = (id) => {
  if (!id) return false;
  const regex = /^[a-fA-F0-9]{24}$/;
  return regex.test(id);
};

export function authenticateUser(token) {
  const jwt = token || getAuthData();
  const companyId = getAuthData('activeProfile') || '';
  if (!jwt) return [false];
  try {
    const decoded = jwtDecode(jwt);
    if (!decoded || !decoded.exp) return [false];
    const now = new Date();
    const nowInSec = Math.floor(now.getTime() * 0.001);
    return [
      decoded.exp > nowInSec,
      decoded,
      companyId && verifyMongodbId(companyId) ? companyId.split('-')[0] : null,
      jwt
    ];
  } catch (unusedERROR) {
    deleteAuthData();
    return [false];
  }
}

export function getAuthData(name = 'authCode') {
  const cookies = new Cookies();
  return cookies.get(name);
}

export const cleanAllCookies = () => {
  document.cookie = `authCode=;domain=.${
    import.meta.env.REACT_APP_DOMAIN_URL
  };path=/;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  document.cookie = `activeProfile=;domain=.${
    import.meta.env.REACT_APP_DOMAIN_URL
  };path=/;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
};

// export function deleteAuthData(noRedirect = true) {
export function deleteAuthData() {
  cleanAllCookies();
  window.location.replace(`${import.meta.env.REACT_APP_LOGIN_URL}`);
  return true;
}

export function switchActiveProfile(id, reload = true) {
  document.cookie = `activeProfile=${id};domain=.${import.meta.env.REACT_APP_DOMAIN_URL};`;
  reload && window.location.reload();
  return true;
}
export function directUserToMasterHub() {
  window.location.href = `${import.meta.env.REACT_APP_LOGIN_URL}/hub/home`;
  return true;
}
